import React from "react";
import { Navigate } from "react-router-dom";
import { authService } from "./components/Auth/authService";

const PrivateRoute = ({ children }) => {
  return authService.isLoggedIn() && authService.getUserRole() === "USER" ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
