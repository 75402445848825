import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";
import { Link } from "react-router-dom";

const ForgotPasswordForm = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [email, setEmail] = useState(""); // Store email separately
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [resendDisabled, setResendDisabled] = useState(true); // State to control resend OTP button
  const [countdown, setCountdown] = useState(180); // Countdown timer for resend OTP button

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      setCountdown(180); // Reset countdown timer
    }
  }, [countdown]);

  const initialValues = {
    otp: "",
    newPassword: "",
  };

  const handleSendOtp = async (values, { setSubmitting, setFieldError }) => {
    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        "https://thetaravu.com/api_connect/passcheck?email=" + values.email
      );

      if (response.status === 200) {
        // OTP sent successfully
        setSubmitting(false);
        setFieldError("otp", "");
        setFieldError("newPassword", "");
        setEmailVerified(true); // Email verified
        setEmail(values.email); // Store the email
      } else if (response.status === 403) {
        setSubmitting(false);
        setFieldError("email", "User with this email does not exist.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
      setFieldError("email", "Failed to send OTP. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleVerifyOtpAndResetPassword = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        "https://thetaravu.com/api_connect/password",
        {
          email: email, // Pass the stored email here
          newPassword: values.newPassword,
          otp: values.otp,
        }
      );

      if (response.status === 200) {
        // Password changed successfully
        setSubmitting(false);
        setFieldError("otp", "");
        setFieldError("newPassword", "");
        // Handle success message
        setSuccessMessage("Password changed successfully");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
      if (error.response.status === 401) {
        setFieldError("otp", "Incorrect or expired OTP.");
      } else {
        setFieldError("email", "Failed to change password. Please try again.");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResendOtp = async () => {
    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        "https://thetaravu.com/api_connect/passcheck?email=" + email
      );

      if (response.status === 200) {
        // OTP sent successfully
        setResendDisabled(true);
        setCountdown(180); // Reset countdown timer
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex flex-col w-full sm:w-1/2 mx-auto my-20 p-4 bg-white shadow-lg rounded-lg max-w-md">
      <h2 className="text-2xl font-semibold text-center mb-6">
        Forgot Password
      </h2>
      <div>
        <Formik
          initialValues={{ email: "", ...initialValues }} // Add email to initialValues
          onSubmit={handleSendOtp}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              {!emailVerified && (
                <button
                  type="submit"
                  disabled={isSubmitting || loading} // Disable button while loading
                  className="w-full bg-blue-500 text-white p-3 rounded-lg relative"
                >
                  {loading && (
                    <div className="absolute inset-0 flex items-center justify-end mr-4">
                      <div className="w-6 h-6 border-t-2 border-b-2 rounded-full animate-spin"></div>
                    </div>
                  )}
                  Send OTP
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {emailVerified && (
        <div className="mt-10">
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleVerifyOtpAndResetPassword}
              validate={(values) => {
                const errors = {};
                if (!values.otp) {
                  errors.otp = "Required";
                }
                if (!values.newPassword) {
                  errors.newPassword = "Required";
                } else if (values.newPassword.length < 8) {
                  errors.newPassword = "Password must be at least 8 characters";
                }
                return errors;
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <label
                      htmlFor="otp"
                      className="block text-sm font-medium text-gray-700"
                    >
                      OTP
                      <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="otp"
                      placeholder="Enter OTP"
                      className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                    />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Password
                      <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="password"
                      name="newPassword"
                      placeholder="Enter new password"
                      className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting || loading} // Disable button while loading
                    className="w-full bg-blue-500 text-white p-3 rounded-lg relative"
                  >
                    {loading && (
                      <div className="absolute inset-0 flex items-center justify-end mr-4">
                        <div className="w-6 h-6 border-t-2 border-b-2 rounded-full animate-spin"></div>
                      </div>
                    )}
                    Change Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="text-green-500 text-center mt-4">{successMessage}</div>
      )}
      {
        // Show resend OTP button only if email is verified
        emailVerified && (
          <div className="text-center mt-4">
            Resend OTP in{" "}
            <span className="text-blue-500 font-semibold">
              {
                // Format countdown to mm:ss
                ("0" + Math.floor(countdown / 60)).slice(-2) +
                  ":" +
                  ("0" + (countdown % 60)).slice(-2)
              }
            </span>{" "}
            <button
              className="ml-2 text-blue-500 underline"
              onClick={handleResendOtp}
              hidden={resendDisabled || loading}
            >
              Resend OTP
            </button>
          </div>
        )
      }
      <p className="text-center mt-4">
        Go to
        <Link to="/login" className="text-blue-500">
          {" "}
          Login
        </Link>
      </p>
    </div>
  );
};

export default ForgotPasswordForm;
