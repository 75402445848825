import { Slider } from "@mui/material";
import React, { useState } from "react";

const FiltersModal = ({
  filters,
  handleInputChange,
  handleApplyFilters,
  handleClearFilters,
  toggleModal,
  modalRef,
  handleSliderChange,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);

  // Function to handle clearing the input field
  const clearInput = (filterKey) => {
    handleInputChange({ target: { value: "", name: filterKey } }, filterKey);
  };

  const handlePriceChange = (event, newValue) => {
    const [minPrice, maxPrice] = newValue;
    setLocalFilters({
      ...localFilters,
      minPrice: minPrice.toString(),
      maxPrice: maxPrice.toString(),
    });

    // Call handleSliderChange callback with new slider values
    handleSliderChange(minPrice.toString(), maxPrice.toString());
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg p-6 w-72 md:w-96 lg:w-120"
      >
        <h2 className="text-lg font-semibold mb-4">Filters</h2>
        {/* Price Sort */}
        <div className="input-wrapper mb-4 relative">
          <label
            htmlFor="price"
            className="absolute top-0 -mt-2 bg-white px-2 text-xs font-semibold"
          >
            Price Sort
          </label>
          <select
            id="price"
            onChange={(e) => handleInputChange(e, "priceSort")}
            value={filters.priceSort}
            className="p-2 border border-gray-300 rounded shadow-sm w-full pl-3"
          >
            <option value="">Price Sort</option>
            <option value="l-h">Low to High</option>
            <option value="h-l">High to Low</option>
          </select>
          {filters.priceSort && (
            <button
              className="absolute top-0 right-0 mr-2 mt-2 focus:outline-none font-bold text-red-500"
              onClick={() => clearInput("priceSort")}
            >
              X
            </button>
          )}
        </div>
        {/* Price Range */}
        <div className="input-wrapper mb-4">
          <label className="block text-xs font-semibold mb-1">
            Price Range
          </label>
          <Slider
            value={[
              parseInt(localFilters.minPrice),
              parseInt(localFilters.maxPrice),
            ]}
            onChange={handlePriceChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={9999999000}
          />
          <div className="flex justify-between text-sm">
            <span>Min Price: {localFilters.minPrice}</span>
            <span>
              Max Price:{" "}
              {localFilters.maxPrice === "9999999000"
                ? "9999999000+"
                : localFilters.maxPrice}
            </span>
          </div>
          {(localFilters.minPrice || localFilters.maxPrice) && (
            <button
              className="absolute top-0 right-0 mr-2 mt-2 focus:outline-none font-bold text-red-500"
              onClick={() => {
                clearInput("minPrice");
                clearInput("maxPrice");
              }}
            >
              X
            </button>
          )}
        </div>
        {/* Location */}
        <div className="input-wrapper mb-4 relative">
          <label
            htmlFor="location"
            className="absolute top-0 -mt-2 bg-white px-2 text-xs font-semibold"
          >
            Location
          </label>
          <input
            type="text"
            id="location"
            placeholder="Location"
            value={filters.location}
            onChange={(e) => handleInputChange(e, "location")}
            className="p-2 border border-gray-300 rounded shadow-sm w-full pl-3"
          />
          {filters.location && (
            <button
              className="absolute top-0 right-0 mr-2 mt-2 focus:outline-none font-bold text-red-500"
              onClick={() => clearInput("location")}
            >
              X
            </button>
          )}
        </div>
        {/* From Date */}
        <div className="input-wrapper mb-4 relative">
          <label
            htmlFor="fromDate"
            className="absolute top-0 -mt-2 bg-white px-2 text-xs font-semibold"
          >
            From Date
          </label>
          <input
            type="date"
            id="fromDate"
            value={filters.fromDate}
            onChange={(e) => handleInputChange(e, "fromDate")}
            className="p-2 border border-gray-300 rounded shadow-sm w-full pl-3"
          />
          {filters.fromDate && (
            <button
              className="absolute top-0 right-0 mr-2 mt-2 focus:outline-none font-bold text-red-500"
              onClick={() => clearInput("fromDate")}
            >
              X
            </button>
          )}
        </div>
        {/* Till Date */}
        <div className="input-wrapper mb-4 relative">
          <label
            htmlFor="tillDate"
            className="absolute top-0 -mt-2 bg-white px-2 text-xs font-semibold"
          >
            Till Date
          </label>
          <input
            type="date"
            id="tillDate"
            value={filters.tillDate}
            onChange={(e) => handleInputChange(e, "tillDate")}
            className="p-2 border border-gray-300 rounded shadow-sm w-full pl-3"
          />
          {filters.tillDate && (
            <button
              className="absolute top-0 right-0 mr-2 mt-2 focus:outline-none font-bold text-red-500"
              onClick={() => clearInput("tillDate")}
            >
              X
            </button>
          )}
        </div>
        <div className="flex justify-between">
          <button
            onClick={handleApplyFilters}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded shadow-md mr-2 text-sm"
          >
            Apply Filters
          </button>
          <button
            onClick={handleClearFilters}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded shadow-md mr-2 text-sm"
          >
            Clear All
          </button>
          <button
            onClick={toggleModal}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded shadow-md text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
