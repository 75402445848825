import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "./authService";

const LoginForm = () => {
  const navigate = useNavigate();
  const initialValuesLogin = {
    email: "",
    password: "",
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true); // Start loading
      const { email, password } = values;
      const userData = {
        email,
        password,
      };
      const response = await authService.login(userData);

      if (response.role === "ROLE_ADMIN") {
        authService.setToken(response.jwtToken);
        navigate("/adminPage");
      } else if (response.role === "ROLE_USER") {
        authService.setToken(response.jwtToken);
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <div className="flex flex-col w-full sm:w-1/2 mx-auto my-20 p-4 bg-white shadow-lg rounded-lg max-w-md">
      <h1 className="text-3xl font-bold text-center mt-4 text-blue-500">
        Login
      </h1>
      <Formik
        initialValues={initialValuesLogin}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Required";
          } else if (values.password.length < 8) {
            errors.password = "Password must be at least 8 characters";
          }
          return errors;
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
                <span className="text-red-500">*</span>
              </label>
              <Field
                type="email"
                name="email"
                id="email"
                className={`border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none ${
                  errors.email && touched.email
                    ? "border-red-500"
                    : "focus:border-blue-500"
                } w-full`}
                placeholder="Email"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 mt-4"
              >
                Password
                <span className="text-red-500">*</span>
              </label>
              <Field
                type="password"
                name="password"
                id="password"
                className={`border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none ${
                  errors.password && touched.password
                    ? "border-red-500"
                    : "focus:border-blue-500"
                } w-full`}
                placeholder="Password"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-3 rounded-lg mt-4 relative"
              disabled={loading}
            >
              {loading && (
                <div className="absolute inset-0 flex items-center justify-end mr-4">
                  <div className="w-6 h-6 border-t-2 border-b-2 rounded-full animate-spin"></div>
                </div>
              )}
              {loading ? "Logging in..." : "Login"}
            </button>
          </Form>
        )}
      </Formik>

      {/* Display error message if error state is not empty */}
      {error && (
        <p className="text-red-500 text-sm text-center mt-2">{error}</p>
      )}

      {/* Link to navigate to registration page */}
      <p className="text-center my-4">
        Don't have an account?{" "}
        <Link to="/register" className="text-blue-500">
          Register
        </Link>
      </p>

      {/* Forget Password */}
      <p className="text-center my-4 hover:underline cursor-pointer">
        <Link to="/forgetPassword" className="text-blue-500">
          Forget Password ?
        </Link>
      </p>
    </div>
  );
};

export default LoginForm;
