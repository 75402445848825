import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom for navigation
import { authService } from "../components/Auth/authService";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchUsers(); // You can uncomment this line to fetch real data
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://thetaravu.com/api_connect/user",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authService.getToken()}`,
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchHistory = async (email) => {
    try {
      const response = await axios.get(
        `https://thetaravu.com/api_connect/history?email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authService.getToken()}`,
          },
        }
      );
      setHistory(response.data);
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const handleShowHistory = async (email) => {
    await fetchHistory(email);
    setSelectedUser(email);
  };

  // Mock data
  const mockData = [
    {
      id: 1,
      name: "kalpesh",
      email: "kap@123",
      mob: "9403306989",
      country: "India",
    },
    {
      id: 2,
      name: "Kartik",
      email: "Kartik@gmail.com",
      mob: "9403306989",
      country: "India",
    },
    {
      id: 52,
      name: "Akash",
      email: "akash@gmail.com",
      mob: "7057224597",
      country: "India",
    },
  ];

  return (
    <div className="container mx-auto px-4 pt-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">List of Users</h1>
        <Link
          to="/suggestions" // Navigate to suggestions list page
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
        >
          Show Suggestions
        </Link>
      </div>
      <div className="overflow-x-auto border border-gray-200 shadow-md rounded-lg">
        <table className="w-full">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Mobile</th>
              <th className="py-3 px-6 text-left">Country</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {users.map((user, i) => (
              <tr key={user.id} className="hover:bg-gray-100">
                <td className="py-4 px-6">{i + 1}</td>
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.email}</td>
                <td className="py-4 px-6">{user.mobile}</td>
                <td className="py-4 px-6">{user.country}</td>
                <td className="py-4 px-6">
                  <div className="flex flex-wrap">
                    <button
                      onClick={() => handleShowHistory(user.email)}
                      className="mr-2 mb-2 md:mb-0 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Show History
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-8 max-w-md mx-auto rounded shadow-xl overflow-y-auto max-h-screen">
            <h2 className="text-2xl font-bold mb-4">
              History for {selectedUser}
            </h2>
            <ul>
              {history.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <button
              onClick={() => setSelectedUser(null)}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
