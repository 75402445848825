import React from "react";

const ProductCard = ({ product, onClick }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-3 cursor-pointer transition duration-300 transform hover:scale-105 relative">
      <div className="flex justify-end absolute top-0 right-0 p-2 bg-blue-500 text-white rounded-tl-lg font-semibold z-10">
        {product.currency.code === "INR"
          ? "₹" + Math.round(product.currency.value * product.productMaxPrice)
          : product.currency.code === "MYR"
          ? "RM" +
            " " +
            Math.round(product.currency.value * product.productMaxPrice)
          : "$" + product.productMaxPrice}
      </div>
      <div
        onClick={() => onClick(product)}
        className="relative overflow-hidden rounded-lg"
      >
        <img
          className="max-h-64 md:max-h-96 lg:max-h-96 xl:max-h-96 object-contain rounded-t-lg"
          src={product.productImage}
          alt={product.productName}
        />
      </div>
      <div className="mt-4">
        <h1 className="text-xl font-semibold mb-2">{product.productName}</h1>
        <p className="text-gray-700 mb-2">
          ▶️ <span className="font-semibold text-black">Model: </span>
          {product.productSpecification.Model}
        </p>
        <p className="text-gray-700 mb-2">
          ▶️ <span className="font-semibold text-black">Condition: </span>
          {product.productSpecification.Condition}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
