import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CurrencyContext } from "../../context/CurrencyContext";
import { authService } from "../Auth/authService";
import logo from "../../asssets/logonew.png";

const Navbar = () => {
  const navigate = useNavigate();
  const { currency, setCurrency } = useContext(CurrencyContext);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    authService.logOut();
    navigate("/login");
  };

  const countryToCurrency = {
    "India 🇮🇳": "INR",
    "United States 🇺🇸": "USD",
    "Malaysia 🇲🇾": "MYR",
  };

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {authService.isLoggedIn() &&
            authService.getUserRole() === "USER" ? (
              <h1
                onClick={() => navigate("/")}
                className="text-xl font-semibold text-white cursor-pointer"
              >
                <div>
                  <img src={logo} alt="TheTaravu" width={60} height={60} />
                </div>
              </h1>
            ) : (
              <h1
                onClick={() => navigate("/adminPage")}
                className="text-xl font-semibold text-white cursor-pointer"
              >
                <div>
                  <img src={logo} alt="TheTaravu" width={60} height={60}/>
                </div>
              </h1>
            )}
          </div>
          <div className="hidden sm:flex sm:items-center sm:ml-6">
            <div className="flex items-center space-x-4">
              {authService.isLoggedIn() &&
                authService.getUserRole() === "USER" && (
                  <>
                    <div>
                      <select
                        className="bg-gray-700 text-white border border-gray-500 rounded-md py-1 px-2"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        {Object.keys(countryToCurrency).map((country) => (
                          <option
                            key={country}
                            value={countryToCurrency[country]}
                          >
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <NavLink to="/" active={location.pathname === "/"}>
                      Home
                    </NavLink>
                    <NavLink
                      to="/about"
                      active={location.pathname === "/about"}
                    >
                      About
                    </NavLink>
                    <NavLink
                      to="/contact"
                      active={location.pathname === "/contact"}
                    >
                      Contact
                    </NavLink>
                  </>
                )}
            </div>
            <div className="ml-4">
              {authService.isLoggedIn() &&
                (authService.getUserRole() === "USER" ||
                  authService.getUserRole()) && (
                  <button
                    onClick={handleLogout}
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Logout
                  </button>
                )}
            </div>
          </div>
          <div
            className={`sm:hidden ${
              authService.isLoggedIn() ? "block" : "hidden"
            }`}
          >
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          isMenuOpen && authService.isLoggedIn() ? "block" : "hidden"
        } sm:hidden`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 bg-gray-700">
          <div className="flex items-center justify-center pb-2 pt-2">
            <select
              className="bg-gray-700 text-white border border-gray-500 rounded-md py-1 px-2"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              {Object.keys(countryToCurrency).map((country) => (
                <option key={country} value={countryToCurrency[country]}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          <NavLinkMobile
            to="/"
            active={location.pathname === "/"}
            toggleMenu={toggleMenu}
          >
            Home
          </NavLinkMobile>
          <NavLinkMobile
            to="/about"
            active={location.pathname === "/about"}
            toggleMenu={toggleMenu}
          >
            About
          </NavLinkMobile>
          <NavLinkMobile
            to="/contact"
            active={location.pathname === "/contact"}
            toggleMenu={toggleMenu}
          >
            Contact
          </NavLinkMobile>
          {authService.isLoggedIn() && (
            <button
              onClick={handleLogout}
              className="block w-full px-3 py-2 text-left rounded-md text-base font-medium text-white bg-red-600 hover:bg-red-700"
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({ to, active, children }) => (
  <Link
    to={to}
    className={`${
      active
        ? "bg-gray-900 text-white"
        : "text-gray-300 hover:bg-gray-700 hover:text-white"
    } px-3 py-2 rounded-md text-sm font-medium`}
  >
    {children}
  </Link>
);

const NavLinkMobile = ({ to, active, children, toggleMenu }) => (
  <Link
    to={to}
    onClick={toggleMenu}
    className={`${
      active
        ? "bg-gray-900 text-white"
        : "text-gray-300 hover:bg-gray-700 hover:text-white"
    } block px-3 py-2 rounded-md text-base font-medium`}
  >
    {children}
  </Link>
);

export default Navbar;
