import { Navigate } from "react-router-dom";
import { authService } from "./components/Auth/authService";

export const AdminPrivateRoute = ({ children }) => {
  return authService.isLoggedIn() && authService.getUserRole() === "ADMIN" ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};
