import React from "react";

const ProductModal = ({ product, onClose }) => {
  const specifications = Object.entries(product.productSpecification);

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="relative p-4 md:p-8 bg-white mx-auto max-w-3xl w-full mt-4 m-auto flex-col flex rounded-lg">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-white bg-red-500 hover:bg-red-600 rounded-full p-2 px-4 text-2xl"
        >
          X
        </button>
        <img
          src={product.productImage}
          alt={product.productName}
          className="h-96 w-full object-contain mb-4 rounded-lg mx-auto md:w-3/4"
        />
        <h2 className="text-2xl font-bold mb-4">{product.productName}</h2>
        <div className="mb-4 text-2xl">
          <span className="font-bold">Price: </span>
          <span className="text-blue-500 font-bold">
            {product.currency.code === "INR"
              ? "₹" +
                Math.round(product.currency.value * product.productMaxPrice)
              : product.currency.code === "MYR"
              ? "RM" +
                " " +
                Math.round(product.currency.value * product.productMaxPrice)
              : "$" + product.productMaxPrice}
          </span>
        </div>
        <h3 className="text-xl font-bold mb-2">Specifications:</h3>
        <ul className="list-disc pl-4">
          {specifications.map(
            ([key, value], index) =>
              value !== "not present" && (
                <li key={index} className="text-gray-700 mb-2">
                  <span className="font-bold">{key}:</span> {value}
                </li>
              )
          )}
        </ul>
        <h3 className="text-xl font-bold mt-4 mb-2">Description:</h3>
        <p className="mb-4">{product.productDescription}</p>
      </div>
    </div>
  );
};

export default ProductModal;
