import React from "react";

const Contact = () => {
  return (
    <div className="max-w-md mx-auto mt-8 px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-2xl font-semibold leading-6 pb-3 text-gray-900">
            Contact Us
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Get in touch with us for any questions or concerns.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <a href="mailto:sakd@example.com">sakd@example.com</a>
              </dd>

              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <a href="tel:555-555-5555">555-555-5555</a>
              </dd>

              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                1234 Elm St. New York, NY 10001
              </dd>

              <dt className="text-sm font-medium text-gray-500">Hours</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <div>Mon-Fri: 9am-5pm</div>
                <div>Sat-Sun: 11am-4pm</div>
              </dd>

              <dt className="text-sm font-medium text-gray-500">Social</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                <div>
                  <button
                    className="text-indigo-500 hover:text-indigo-600"
                    onClick={() =>
                      (window.location.href = "https://www.facebook.com")
                    }
                  >
                    Facebook
                  </button>
                </div>
                <div>
                  <button
                    className="text-indigo-500 hover:text-indigo-600"
                    onClick={() =>
                      (window.location.href = "https://www.twitter.com")
                    }
                  >
                    Twitter
                  </button>
                </div>
                <div>
                  <button
                    className="text-indigo-500 hover:text-indigo-600"
                    onClick={() =>
                      (window.location.href = "https://www.instagram.com")
                    }
                  >
                    Instagram
                  </button>
                </div>
                <div>
                  <button
                    className="text-indigo-500 hover:text-indigo-600"
                    onClick={() => (window.location.href = "#")}
                  >
                    WhatsApp
                  </button>
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Contact;
