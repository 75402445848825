import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    country: "",
    otp: "",
  });
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkAllFields = () => {
    const { name, email, mobile, password, confirmPassword, country, otp } =
      formData;
    return (
      name && email && mobile && password && confirmPassword && country && otp
    );
  };

  const handleVerifyEmail = async () => {
    try {
      const { email } = formData;

      // First API call to check if user with the email already exists
      const checkUserResponse = await axios.post(
        `https://thetaravu.com/api_connect/check?email=${email}`
      );

      if (checkUserResponse.status === 409) {
        setErrorMessage("User with this email already exists");
      } else if (checkUserResponse.status === 200) {
        setErrorMessage("");
        setEmailVerified(true);
        setOtpSent(true);
      } else if (checkUserResponse.status === 417) {
        setErrorMessage("Sending OTP failed due to an internal problem");
      }
    } catch (error) {
      console.error("Error during email verification:", error);
      setErrorMessage("Email verification failed. Please try again later.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Perform form validation here
      if (checkAllFields() && formData.password === formData.confirmPassword) {
        // Second API call to register user with OTP
        const registerResponse = await axios.post(
          "https://thetaravu.com/api_connect/user",
          {
            name: formData.name,
            email: formData.email,
            mobile: formData.mobile,
            password: formData.password,
            country: formData.country,
            otp: formData.otp.toString(),
          }
        );

        if (registerResponse.status === 200) {
          setSuccessMessage("Registration successful!");
          // You can redirect the user to the login page or do something else
        }
      } else {
        if (!checkAllFields()) {
          setErrorMessage("Please fill in all the form fields");
        } else {
          setErrorMessage("Password and Confirm Password do not match");
        }
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setErrorMessage("Registration failed. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col w-full sm:w-1/2 mx-auto my-10 p-4 bg-white shadow-lg rounded-lg max-w-md">
      <h1 className="text-3xl font-bold text-center mt-4 text-blue-500">
        Register
      </h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
            <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
            placeholder="Email"
            required
          />
        </div>
        {emailVerified ? (
          <>
            <div className="mb-4">
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-gray-700"
              >
                OTP
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="otp"
                id="otp"
                value={formData.otp}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Enter OTP"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Name"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile No
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                id="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Mobile No"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Set Password
                <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Set Password"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
                <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Confirm Password"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
                className="border-2 border-gray-300 p-3 mt-1 rounded-lg focus:outline-none w-full"
                placeholder="Country"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg focus:outline-none w-full"
              disabled={!checkAllFields()}
            >
              Register
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleVerifyEmail}
            className="bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg focus:outline-none w-full"
          >
            {otpSent ? "Resend OTP" : "Send OTP"}
          </button>
        )}
        {errorMessage && (
          <div className="text-red-500 text-sm mt-4">{errorMessage}</div>
        )}
      </form>
      <p className="text-center my-4">
        {successMessage
          ? "Registration successful! "
          : "Already have an account? "}{" "}
        <Link to="/login" className="text-blue-500">
          Login
        </Link>
      </p>

      {successMessage && (
        <p className="text-center my-4 text-green-500 font-bold text-lg">
          {successMessage}
        </p>
      )}
    </div>
  );
};

export default RegisterForm;
