import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authService } from "../components/Auth/authService";

const SuggestionsListPage = () => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const fetchSuggestions = async () => {
    try {
      const response = await axios.get(
        "https://thetaravu.com/api_connect/suggestion",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authService.getToken()}`,
          },
        }
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-10">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Suggestions List</h1>
        <Link
          to="/adminPage"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Show Users
        </Link>
      </div>
      <div className="overflow-x-auto border border-gray-200 shadow-md rounded-lg">
        <table className="w-full table-auto">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Suggestion</th>
              <th className="py-3 px-6 text-left">Created At</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {suggestions.map((suggestion, i) => (
              <tr key={suggestion.id} className="hover:bg-gray-100">
                <td className="py-4 px-6">{i + 1}</td>
                <td className="py-4 px-6">{suggestion.name}</td>
                <td className="py-4 px-6">{suggestion.email}</td>
                <td className="py-4 px-6 break-words max-w-xs">
                  {suggestion.suggestionText}
                </td>
                <td className="py-4 px-6">
                  {new Date(suggestion.createdAt).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuggestionsListPage;
