// import axiosInstance from "../axios/axiosInstance";

import axios from "axios";
import { jwtDecode } from "jwt-decode";

const setToken = (token) => {
  localStorage.setItem("token", token);
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return null;
};

const login = async (userData) => {
  try {
    const response = await axios.post(
      "https://thetaravu.com/api_connect/auth/login",
      userData
    );

    setToken(response.data.jwtToken);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Invalid email or password. Please try again.");
  }
};

const getUserEmail = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);
    return payLoad?.email;
  }
  return null;
};

const getUserRole = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);

    return payLoad?.role;
  }
  return null;
};

const isLoggedIn = () => {
  const token = getToken();
  if (token) {
    const payLoad = jwtDecode(token);
    const isLogin = Date.now() < payLoad.exp * 1000;
    return isLogin;
  }
};

const logOut = () => {
  localStorage.removeItem("token");
};

export const authService = {
  logOut,
  getToken,
  setToken,
  login,
  getUserEmail,
  getUserRole,
  isLoggedIn,
};
