import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchData } from "../api/api";
import { authService } from "../components/Auth/authService";
import FiltersModal from "../components/Filters/FiltersModal";
import Pagination from "../components/Pagination/Pagination";
import ProductContainer from "../components/Products/ProductContainer";
import { CurrencyContext } from "../context/CurrencyContext";

const Home = () => {
  const { currency } = useContext(CurrencyContext);

  const [filters, setFilters] = useState({
    searchTerm: "",
    priceSort: "",
    minPrice: "0",
    maxPrice: "9999999000",
    location: "",
    fromDate: "",
    tillDate: "",
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [filteredData, setFilteredData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuggestionPopup, setShowSuggestionPopup] = useState(false);
  const [suggestionText, setSuggestionText] = useState("");
  const modalRef = useRef(null);
  const [searched, setSearched] = useState(false); // State to track whether search has been performed

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleSuggestionPopup = () => {
    setShowSuggestionPopup(!showSuggestionPopup);
  };

  const handleInputChange = (event, key) => {
    setFilters({ ...filters, [key]: event.target.value });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleApplyFilters = () => {
    setPage(1);
    toggleModal(); // Close the modal after applying filters
    fetchDataAndUpdateState();
  };

  const handleSliderChange = (minPrice, maxPrice) => {
    // Update state with new slider values
    setFilters({
      ...filters,
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
  };

  const handleSuggestionSubmit = async () => {
    try {
      const response = await axios.post(
        `https://thetaravu.com/api_connect/suggestion?text=${encodeURIComponent(
          suggestionText
        )}`,
        null, // No data payload
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authService.getToken()}`,
          },
        }
      );

      if (response.status === 200) {
        // Suggestion submitted successfully
        toggleSuggestionPopup();
      } else {
        // Handle error response
        console.error("Error submitting suggestion:", response.data);
      }
    } catch (error) {
      console.error("Error submitting suggestion:", error);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [page, perPage, currency]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = "hidden"; // Disable scrolling when modal is open
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = "auto"; // Enable scrolling when modal is closed
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showModal]);

  console.log("Currency:", currency);

  const fetchDataAndUpdateState = async () => {
    try {
      const data = await fetchData(filters, Math.ceil(page), perPage, currency);
      setFilteredData(data);
      // Once data is fetched, mark searched as true
      setSearched(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      priceSort: "",
      minPrice: "",
      maxPrice: "",
      location: "",
      fromDate: "",
      tillDate: "",
    });
    setSearched(false); // Clearing filters means user hasn't searched
  };

  // Render the introductory section if user hasn't searched
  const renderIntroSection = () => {
    if (!searched && filters.searchTerm === "") {
      return (
        <div className="my-4 text-center p-4 lg:mt-8">
          <h2 className="text-xl font-semibold mb-4">Search Machines....</h2>
          <div className="flex flex-wrap justify-center gap-6 lg:gap-12 lg:mt-12">
            <div className="border border-gray-300 rounded-lg shadow-2xl flex flex-col overflow-hidden max-w-sm hover:scale-105 transition-transform duration-300">
              <img
                src="https://images.pexels.com/photos/1145434/pexels-photo-1145434.jpeg"
                alt="Cutting Tools"
                className="object-cover h-48 w-full rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">Cutting Tools</h3>
                <p className="text-gray-600">
                  Find the best cutting tools for your business.
                </p>
              </div>
            </div>
            <div className="border border-gray-300 rounded-lg shadow-2xl flex flex-col overflow-hidden max-w-sm hover:scale-105 transition-transform duration-300">
              <img
                src="https://images.pexels.com/photos/1078884/pexels-photo-1078884.jpeg"
                alt="Cranes"
                className="object-cover h-48 w-full rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">Cranes</h3>
                <p className="text-gray-600">
                  Find the best cranes for your construction business.
                </p>
              </div>
            </div>
            <div className="border border-gray-300 rounded-lg shadow-2xl flex flex-col overflow-hidden max-w-sm hover:scale-105 transition-transform duration-300">
              <img
                src="https://images.pexels.com/photos/15603045/pexels-photo-15603045/free-photo-of-lathe-in-workshop.jpeg"
                alt="Lathe Machines"
                className="object-cover h-48 w-full rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="font-semibold text-lg mb-2">Lathe Machines</h3>
                <p className="text-gray-600">
                  Find the best lathe machines for your business.
                </p>
              </div>
            </div>
          </div>
          <p className="mt-12 text-gray-600">
            Explore more options by searching...
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={showModal ? "modal-open" : ""}>
      {/* Search bar and buttons to open modal and suggestion popup */}
      <div className="flex flex-wrap justify-center items-center my-4">
        <input
          type="text"
          placeholder="Search Products"
          value={filters.searchTerm}
          onChange={(e) => handleInputChange(e, "searchTerm")}
          className="p-2 px-4 border border-gray-300 rounded-l-lg shadow-xl mb-2 "
        />
        <button
          onClick={fetchDataAndUpdateState}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-3 rounded-r-lg shadow-xl mb-2"
        >
          Search
        </button>
        <div className="flex flex-wrap justify-evenly items-center w-[80%] md:w-auto mb-2">
          <button
            onClick={toggleModal}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-3 rounded-lg shadow-xl mb-2 md:mb-0 md:ml-2 md:mr-2"
          >
            Filters
          </button>
          <button
            onClick={toggleSuggestionPopup}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-3 rounded-lg shadow-xl mb-2 md:mb-0 md:ml-2 md:mr-2"
          >
            Add Suggestions
          </button>
        </div>
        <div className="ml-2 mb-2">
          <span className="font-semibold text-sm">Per Page:</span>
          <select
            value={perPage}
            onChange={(e) => setPerPage(e.target.value)}
            className="ml-2 p-1 border-2 border-black-500 rounded shadow-sm text-sm md:w-auto"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      {showModal && (
        <FiltersModal
          filters={filters}
          handleInputChange={handleInputChange}
          handleApplyFilters={handleApplyFilters}
          toggleModal={toggleModal}
          modalRef={modalRef}
          handleClearFilters={handleClearFilters}
          handleSliderChange={handleSliderChange}
        />
      )}
      {showSuggestionPopup && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center z-10">
          <div className="bg-white p-8 rounded shadow-lg relative">
            <button
              onClick={toggleSuggestionPopup}
              className="absolute top-0 right-0 mr-4 mt-4 text-white bg-red-500 hover:bg-red-600 font-semibold py-2 px-4 rounded-full"
            >
              X
            </button>
            <h2 className="text-xl font-semibold mb-4">Add Suggestion</h2>
            <input
              type="text"
              placeholder="Enter your suggestion"
              value={suggestionText}
              onChange={(e) => setSuggestionText(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded shadow-sm mb-4"
            />
            <button
              onClick={handleSuggestionSubmit}
              className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow-xl"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {!searched && filters.searchTerm === "" && renderIntroSection()}
      {filteredData && filteredData.totalCount > 0 ? (
        <ProductContainer products={filteredData} />
      ) : searched ? (
        <p className="text-center mt-4">No products found.</p>
      ) : null}
      {filteredData?.totalCount > 0 && filteredData?.totalCount > perPage && (
        <Pagination
          totalPages={Math.ceil(filteredData.totalCount / perPage)}
          currentPage={filteredData.page}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Home;
