import { authService } from "../components/Auth/authService";
import axios from "axios";

export const fetchData = async (filters, page, perPage, currency) => {
  try {
    let apiUrl = `https://thetaravu.com/api_connect/product`;
    let params = [];

    for (const key in filters) {
      if (filters[key].trim() !== "") {
        params.push(`${key}=${encodeURIComponent(filters[key].trim())}`);
      }
    }

    if (params.length > 0) {
      apiUrl += "?" + params.join("&");
    }

    apiUrl += `&currency=${currency}&page=${page}&perPage=${perPage}`;

    const token = authService.getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(apiUrl, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
