import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const renderPageButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    if (startPage > 1) {
      buttons.push(
        <button
          key={1}
          onClick={() => onPageChange(1)}
          className={`${
            currentPage === 1
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } font-semibold py-2 px-4 rounded shadow-md mr-2`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        buttons.push(
          <span
            key="frontEllipsis"
            className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded shadow-md mr-2"
          >
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`${
            currentPage === i
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } font-semibold py-2 px-4 rounded shadow-md mr-2`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        buttons.push(
          <span
            key="backEllipsis"
            className="bg-blue-200 text-blue-500 font-semibold py-2 px-4 rounded shadow-md mr-2"
          >
            ...
          </span>
        );
      }
      buttons.push(
        <button
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          className={`${
            currentPage === totalPages
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } font-semibold py-2 px-4 rounded shadow-md mr-2`}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="flex justify-center my-4">
      <button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        className={
          currentPage === 1
            ? "bg-gray-200 text-blue-500 font-semibold py-2 px-4 rounded shadow-md mr-2"
            : "bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded shadow-md mr-2"
        }
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {renderPageButtons()}
      <button
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        className={
          currentPage === totalPages
            ? "bg-gray-200 text-blue-500 font-semibold py-2 px-4 rounded shadow-md ml-2"
            : "bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded shadow-md ml-2"
        }
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
