import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="max-w-4xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h1 className="text-2xl font-bold mb-4">About Us</h1>
          <p className="text-gray-700 mt-4">
            This website is created to provide information about Machinaries and
            Equipments. We provide information about various types of
            machinaries and equipments.
          </p>

          <h3
            className="text-gray-700 mt-8"
            style={{ borderTop: "1px solid #d1d5db", paddingTop: "1.5rem" }}
          >
            <strong>Note:</strong> Data in this Website is collected from
            various websites. It is just for information purpose
          </h3>

          <h4 className="font-bold mt-8 mb-4">
            Contact us at{" "}
            <Link to="/contact" className="text-blue-500">
              Contact
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default About;
