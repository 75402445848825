import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AdminPrivateRoute } from "./AdminPrivateRoute";
import PrivateRoute from "./PrivateRoute";
import LoginForm from "./components/Auth/LoginForm";
import RegisterForm from "./components/Auth/RegisterForm";
import Navbar from "./components/Navbar/Navbar";
import { CurrencyContext } from "./context/CurrencyContext";
import About from "./pages/About";
import AdminPage from "./pages/AdminPage";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import SuggestionsListPage from "./pages/SuggestionsListPage";
import ForgotPasswordForm from "./components/Auth/ForgetPasswordForm";

function App() {
  const initialCurrency = localStorage.getItem("currency") || "INR";
  const [currency, _setCurrency] = useState(initialCurrency);

  const setCurrency = (newCurrency) => {
    localStorage.setItem("currency", newCurrency);
    _setCurrency(newCurrency);
  };

  useEffect(() => {
    const savedCurrency = localStorage.getItem("currency");
    if (savedCurrency) {
      setCurrency(savedCurrency);
    }
  }, []);

  return (
    <div className="App">
      <CurrencyContext.Provider value={{ currency, setCurrency }}>
        <Navbar />
        {/* <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/adminPage" element={<AdminPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/suggestions" element={<SuggestionsListPage />} />
          </Route>
        </Routes> */}

        <Routes>
          <Route path="/login" element={<LoginForm />}></Route>
          <Route path="/register" element={<RegisterForm />}></Route>
          <Route
            path="/forgetPassword"
            element={<ForgotPasswordForm />}
          ></Route>
          {/* User Routes */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/about"
            element={
              <PrivateRoute>
                <About />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/contact"
            element={
              <PrivateRoute>
                <Contact />
              </PrivateRoute>
            }
          ></Route>

          {/* Admin Routes */}
          <Route
            path="/adminPage"
            element={
              <AdminPrivateRoute>
                <AdminPage />
              </AdminPrivateRoute>
            }
          ></Route>
          <Route
            path="/suggestions"
            element={
              <AdminPrivateRoute>
                <SuggestionsListPage />
              </AdminPrivateRoute>
            }
          ></Route>
        </Routes>
      </CurrencyContext.Provider>
    </div>
  );
}

export default App;
